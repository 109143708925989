@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic');

/* Fonts */
$roboto: 'Roboto', sans-serif;


/* Colors */
$black: #000;
$white: #fff;
$color-primary: #D39598;
$grey: #8A8A8A;
$grey-light: #F2F2F2;
