@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic");
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden !important; }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }
  *::after, *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    outline: 0; }
  *:active, *:focus, *:hover, *:visited {
    outline: 0;
    -webkit-tap-highlight-color: transparent; }

/* Webkit autofill background fix */
html body input:-webkit-autofill, html body input:-webkit-autofill:hover, html body input:-webkit-autofill:focus, html body input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s !important; }

button, input, hr, optgroup, select, textarea, p, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

progress, sub, sup {
  vertical-align: baseline; }

pre, code {
  overflow: auto; }

embed, iframe, img, legend, object, pre, details {
  max-width: 100%;
  display: block;
  border: 0;
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

a {
  background-color: transparent;
  text-decoration: unset;
  color: unset; }
  a:active, a:focus, a:hover, a:visited {
    color: unset;
    text-decoration: unset; }

b, strong {
  font-weight: bold; }

code, kbd, samp, pre {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border: none;
  height: auto; }

button, select, input, textarea, label {
  text-transform: unset;
  border: none;
  outline: none;
  background: unset; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px; }

textarea {
  width: 100%;
  display: block;
  resize: vertical; }

[type=checkbox], [type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.hide, .hidden, [hidden] {
  display: none; }

body, button, input, optgroup, select, textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

cite, dfn, em, i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: .9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  padding: 1.6em;
  color: #000; }

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: .9375rem; }

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

ins, mark {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

ol {
  list-style-type: decimal; }

li > ol, li > ul {
  margin: 0;
  padding: 0; }

dt {
  font-weight: 700; }

dd {
  margin: 0 1.5em 1.5em; }

figure {
  margin: 0; }

table {
  margin: 0;
  width: auto; }

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=range]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  color: inherit; }

a:active, a:focus, a:hover {
  color: inherit; }

input {
  appearance: none; }
  input[type=search] {
    appearance: textfield;
    outline: none; }
  input::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden; }

/* Old Browser */
.browsers__placeholder {
  font-family: "Helvetica", sans-serif;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-image: none !important;
  text-align: center;
  background-color: black !important;
  font-size: 20px;
  color: #fff;
  z-index: 100000000000000000000000000000000000;
  line-height: 1.6; }
  .browsers__placeholder p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    font-family: inherit; }
  .browsers__placeholder a {
    color: red; }

/* Fonts */
/* Colors */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.transition {
  transition: 0.3s all ease; }

.bg-transparent {
  background: transparent !important; }

.overflow-hidden {
  overflow: hidden; }

.relative {
  position: relative; }

.abosolute {
  position: absolute; }

.fixed {
  position: fixed; }

.static {
  position: static; }

.uppercase {
  text-transform: uppercase; }

.text-decoration-none {
  text-decoration: none !important; }
  .text-decoration-none a {
    text-decoration: none !important; }

.aligncenter, .center {
  text-align: center; }

.alignleft, .left {
  text-align: left; }

.alignright, .right {
  text-align: right; }

*,
*::before,
*::after {
  box-sizing: border-box; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .container.sm-wide {
      padding: 0; } }

@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .container {
    width: 90%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px;
    max-width: 100%; } }

@media (min-width: 1440px) {
  .container {
    width: 1410px;
    max-width: 100%; } }

@media (min-width: 1600px) {
  .container {
    width: 1570px;
    max-width: 100%; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  html .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  html .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

body {
  font-family: "Roboto", sans-serif; }

section {
  position: relative; }

/* Header */
.header {
  background-color: #fff;
  z-index: 100;
  width: 100%;
  margin-bottom: 20px; }

.header.fixed {
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 0; }

.header-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  @media (max-width: 767px) {
    .header-wrapper {
      flex-direction: row;
      justify-content: space-between; } }

.nav {
  padding: 20px 0; }
  .nav .main-menu {
    display: flex;
    justify-content: center; }
    .nav .main-menu .main-menu_item {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #8A8A8A;
      margin-right: 40px;
      transition: .3s color; }
      .nav .main-menu .main-menu_item:last-child {
        margin-right: 0; }
        .nav .main-menu .main-menu_item:last-child::after {
          display: none; }
      .nav .main-menu .main-menu_item:hover {
        color: #000; }
      .nav .main-menu .main-menu_item::after {
        content: ' ';
        display: block;
        width: 1px;
        height: 14px;
        background-color: #8A8A8A;
        margin-left: 40px; }
  @media (max-width: 767px) {
    .nav {
      display: none; } }

#burger {
  display: none;
  flex-direction: column;
  justify-content: center;
  width: 20px;
  height: 16px;
  z-index: 101;
  /* position: absolute;
    left: 20px; */ }
  #burger div {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #000;
    margin-bottom: 2px;
    transition: .3s all; }
  @media (max-width: 767px) {
    #burger {
      display: flex; } }

#burger.active div {
  background-color: #fff; }
  #burger.active div:nth-child(1) {
    transform: rotate(45deg) translateY(3px); }
  #burger.active div:nth-child(2) {
    display: none; }
  #burger.active div:nth-child(3) {
    transform: rotate(-45deg) translateY(-4px); }

.overlay-wrapper {
  transform: translateY(-100%);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #8A8A8A;
  z-index: 100;
  position: fixed;
  transition: 1s ease-in-out;
  overflow-y: hidden; }
  .overlay-wrapper.active {
    transform: translateY(0); }

.mobile-nav-overlay {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.mobile-nav .mobile-nav_item {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #fff;
  transition: .3s;
  z-index: 102; }
  .mobile-nav .mobile-nav_item:hover {
    text-shadow: 1px 1px 1px #000; }

/* SECTION HERO */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .hero .hero-img {
    position: relative;
    width: 80%; }
    .hero .hero-img img {
      width: 100%; }
    @media (max-width: 1440px) {
      .hero .hero-img {
        width: 100%; } }
    .hero .hero-img .hero-heading {
      position: absolute;
      top: 20%;
      left: 25%;
      color: #fff;
      font-size: 82px;
      font-weight: 400;
      text-transform: uppercase;
      width: 100px;
      line-height: 1.4; }
      @media (max-width: 990px) {
        .hero .hero-img .hero-heading {
          font-size: 48px;
          line-height: 1.2; } }
      @media (max-width: 545px) {
        .hero .hero-img .hero-heading {
          font-size: 28px; } }
  .hero .scroll {
    padding-top: 25px;
    cursor: pointer; }

.section-title {
  width: 150px;
  font-size: 24px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000;
  font-weight: 400;
  z-index: 10;
  position: absolute;
  top: 20px;
  left: 220px; }
  @media (max-width: 1199px) {
    .section-title {
      left: auto; } }

/* SECTION SELECT */
.select {
  padding: 20px 0; }
  .select .section-title {
    width: 85px; }
  .select .select-visa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 125px; }
    @media (max-width: 767px) {
      .select .select-visa {
        flex-direction: column; } }

.visa-card {
  width: 50%;
  margin-bottom: 60px;
  padding-left: 150px; }
  .visa-card .visa-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #000;
    text-transform: uppercase; }
  .visa-card .visa-disc {
    width: 270px;
    height: 28px;
    font-size: 14px;
    line-height: 100%;
    margin: 20px 0; }
    @media (max-width: 767px) {
      .visa-card .visa-disc {
        width: 350px; } }
    @media (max-width: 545px) {
      .visa-card .visa-disc {
        width: 310px; } }
  .visa-card .visa-btn {
    padding: 6px 25px;
    text-transform: uppercase;
    border: 1px solid #000;
    font-size: 8px;
    font-weight: 500;
    transition: .3s all; }
    .visa-card .visa-btn:hover {
      box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.5); }
    .visa-card .visa-btn:active {
      color: #fff;
      background-color: #000; }
  @media (max-width: 990px) {
    .visa-card {
      padding-left: 70px; } }
  @media (max-width: 767px) {
    .visa-card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      width: 100%; } }

/* SECTION COSTS */
.costs .section-title {
  width: 85px;
  color: #fff; }

.cost-content {
  display: flex;
  justify-content: center;
  align-items: center; }
  .cost-content .cost-img {
    margin-bottom: 20px; }
  @media (max-width: 767px) {
    .cost-content {
      flex-direction: column; } }

.cost-wrapper {
  display: flex;
  justify-content: center; }

.cost-text .cost-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 30px; }

.cost-text .cost-disc {
  width: 400px;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 20px; }
  @media (max-width: 545px) {
    .cost-text .cost-disc {
      width: 310px; } }

.cost-text .cost-btn {
  padding: 6px 25px;
  text-transform: uppercase;
  border: 1px solid #000;
  font-size: 8px;
  transition: .3s all; }
  .cost-text .cost-btn:hover {
    box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.5); }
  .cost-text .cost-btn:active {
    color: #fff;
    background-color: #000; }

/* SECTION COUNTER */
.counters {
  margin-top: 130px;
  padding-top: 150px; }
  .counters .section-title {
    width: 200px; }

.counter-wrapper {
  display: flex;
  justify-content: space-around; }
  .counter-wrapper .counter-item {
    width: 155px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .counter-wrapper .counter-item .counter-item_num {
      font-size: 64px;
      line-height: 130%;
      /* or 83px */
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #D39598; }
      @media (max-width: 991px) {
        .counter-wrapper .counter-item .counter-item_num {
          font-size: 46px; } }
    .counter-wrapper .counter-item .counter-item_subtitle {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      /* identical to box height, or 22px */
      color: #D39598; }
    @media (max-width: 767px) {
      .counter-wrapper .counter-item {
        width: 50%;
        margin-bottom: 30px; } }
    @media (max-width: 545px) {
      .counter-wrapper .counter-item {
        width: 100%; } }
  @media (max-width: 767px) {
    .counter-wrapper {
      flex-wrap: wrap; } }

/* SECTION EDUCATION */
.education {
  padding-top: 200px; }
  .education .education-img {
    display: flex;
    justify-content: center;
    position: relative; }
    .education .education-img .education-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      .education .education-img .education-text .education-heading {
        font-weight: 500;
        font-size: 70px;
        line-height: 120%;
        /* identical to box height, or 84px */
        text-align: center;
        color: #000; }
        .education .education-img .education-text .education-heading span {
          color: #fff; }
        @media (max-width: 1439px) {
          .education .education-img .education-text .education-heading {
            font-size: 38px;
            color: #fff; } }
        @media (max-width: 545px) {
          .education .education-img .education-text .education-heading {
            font-size: 32px; } }
      .education .education-img .education-text .education-disc {
        margin-top: 35px;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */
        text-align: center;
        color: #fff; }
        @media (max-width: 545px) {
          .education .education-img .education-text .education-disc {
            font-size: 11px;
            margin-top: 25px; } }
  @media (max-width: 990px) {
    .education {
      padding-top: 150px; } }

/* SECTION PARTNERS */
.partners {
  margin-top: 100px;
  padding-top: 120px; }

.partners-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .partners-content .partners-disc {
    width: 666px;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */ }
    @media (max-width: 991px) {
      .partners-content .partners-disc {
        width: auto; } }
  .partners-content .partners-wrapper {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .partners-content .partners-wrapper .partner-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 140px;
      background-color: #F2F2F2; }
      @media (max-width: 990px) {
        .partners-content .partners-wrapper .partner-item {
          width: calc(100%/3 - 15px);
          margin: 10px 0; } }
      @media (max-width: 545px) {
        .partners-content .partners-wrapper .partner-item {
          width: calc(50% - 15px); } }
    @media (max-width: 990px) {
      .partners-content .partners-wrapper {
        flex-wrap: wrap; } }

.cities {
  margin-top: 250px; }
  .cities .cities-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center; }
    @media (max-width: 1999px) {
      .cities .cities-wrapper {
        flex-wrap: wrap; } }
  @media (max-width: 990px) {
    .cities {
      margin-top: 150px; } }

.city-card {
  width: 250px; }
  .city-card .city-card_text {
    width: 250px;
    font-size: 14px;
    line-height: 120%;
    /* or 17px */
    margin: 20px 0; }
  .city-card .city-btn {
    padding: 6px 15px;
    text-transform: uppercase;
    border: 1px solid #000;
    font-size: 8px;
    transition: .3s all; }
    .city-card .city-btn:hover {
      box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.5); }
    .city-card .city-btn:active {
      color: #fff;
      background-color: #000; }
  @media (max-width: 1199px) {
    .city-card {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px; } }
  @media (max-width: 667px) {
    .city-card {
      width: 100%; } }

/* SECTION REVIEWS */
.reviews {
  margin-top: 250px;
  padding: 100px 0; }
  .reviews .reviews-disc {
    display: flex;
    justify-content: center;
    margin-bottom: 50px; }
    .reviews .reviews-disc p {
      text-align: center;
      width: 666px;
      font-size: 14px;
      line-height: 120%;
      /* or 17px */ }
  @media (max-width: 990px) {
    .reviews {
      margin-top: 100px; } }

.review-slider {
  position: relative;
  /* padding-left: 85px; */ }

.slick-slide > div {
  justify-content: center;
  align-items: center;
  display: flex; }

.slide {
  max-width: 350px;
  height: 146px;
  background-color: #F2F2F2;
  padding: 15px 20px; }
  .slide .slide-user {
    display: flex;
    align-items: center; }
    .slide .slide-user .user-text {
      margin-left: 20px; }
      .slide .slide-user .user-text .user-name {
        font-size: 18px;
        line-height: 130%;
        /* or 23px */
        text-transform: uppercase;
        color: #A7A7A7; }
      .slide .slide-user .user-text .user-comment {
        font-size: 14px;
        line-height: 120%;
        /* identical to box height, or 17px */ }
  .slide .slide-action {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .slide .slide-action .action-time {
      display: flex;
      align-items: center; }
      .slide .slide-action .action-time .time-text {
        font-size: 12px;
        line-height: 120%;
        /* or 14px */
        letter-spacing: 0.1em;
        color: #A7A7A7;
        margin-left: 10px; }
    .slide .slide-action .action-btn {
      display: flex;
      font-size: 12px;
      line-height: 120%;
      /* or 14px */
      letter-spacing: 0.1em;
      color: #A7A7A7;
      text-transform: uppercase; }
      .slide .slide-action .action-btn .action-like {
        display: flex;
        align-items: center;
        margin-right: 25px; }
        .slide .slide-action .action-btn .action-like .like-num {
          font-weight: 500;
          font-size: 16px;
          /* identical to box height, or 24px */
          letter-spacing: 0.1em;
          color: #000;
          margin-right: 5px; }

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  z-index: 10; }

.slick-next {
  height: 20px;
  width: 20px;
  border-top: 5px solid #D39598;
  border-right: 5px solid #D39598;
  right: 0;
  transform: rotate(45deg); }

.slick-prev {
  height: 20px;
  width: 20px;
  border-top: 5px solid #D39598;
  border-right: 5px solid #D39598;
  left: 0;
  transform: rotate(-135deg); }

/* SECTION CONTACT */
.contact {
  padding-top: 120px; }
  .contact .section-title {
    width: 100px; }
  .contact .contact-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center; }
    @media (max-width: 990px) {
      .contact .contact-wrapper {
        flex-direction: column; } }

.contact-form button {
  font-weight: 500;
  font-size: 10px;
  line-height: 100%;
  cursor: pointer;
  /* identical to box height, or 8px */
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000;
  padding: 6px 39px;
  border: 1px solid #000;
  transition: .3s all; }
  .contact-form button:hover {
    box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.5); }
  .contact-form button:active {
    color: #fff;
    background-color: #000; }

@media (max-width: 990px) {
  .contact-form {
    width: 100%; } }

.form-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  margin-bottom: 7px;
  color: #C4C4C4; }

input {
  width: 270px;
  height: 35px;
  background-color: #F2F2F2;
  padding: 10px;
  margin-bottom: 25px; }
  @media (max-width: 990px) {
    input {
      width: 100%; } }

textarea {
  width: 270px;
  height: 100px;
  background-color: #F2F2F2;
  padding: 10px;
  margin-bottom: 25px; }
  @media (max-width: 990px) {
    textarea {
      width: 100%; } }

/* FOOTER */
.footer {
  padding: 20px 0;
  background-color: #F2F2F2; }

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .footer-wrapper .social {
    display: flex; }
    .footer-wrapper .social a {
      margin-right: 20px; }
      .footer-wrapper .social a:last-child {
        margin-right: 0; }

.rights {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */
  font-variant: small-caps;
  text-transform: uppercase;
  color: #C4C4C4; }
