body{
    font-family: $roboto;
}
section{
    position: relative;
}
/* Header */
.header{
    background-color: $white;
    z-index: 100;
    width: 100%;
    margin-bottom: 20px;
}
.header.fixed{
    box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.5);
    margin-bottom: 0;
}
.header-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .logo{
        
    }
    @media (max-width: 767px) {
        flex-direction: row;
        justify-content: space-between;
    }
}
.nav{
    padding: 20px 0;
    .main-menu{
        display: flex;
        justify-content: center;
        .main-menu_item{
            display: flex;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: $grey;
            margin-right: 40px;
            transition: .3s color;
            &:last-child{
                margin-right: 0;
                &::after{
                    display: none;
                }
                }
            &:hover{
                color: $black;
            }
            &::after{
                content: ' ';
                display: block;
                width: 1px;
                height: 14px;
                background-color: $grey;
                margin-left: 40px;
            }
        }
    }
    @media (max-width: 767px) {
        display: none;
    }
}

#burger{
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 20px;
    height: 16px;
    z-index: 101;
    
    /* position: absolute;
    left: 20px; */
    div{
      display: block;
      width: 100%;
      height: 3px;
      background-color: $black;
      margin-bottom: 2px;
      transition: .3s all;
    }
    @media (max-width: 767px) {
      display: flex;
    }
  }

  #burger.active div{
    background-color: $white;
    &:nth-child(1){
      transform: rotate(45deg) translateY(3px);
    }
    &:nth-child(2){
      display: none;
    }
    &:nth-child(3){
      transform: rotate(-45deg) translateY(-4px);
    }
}

.overlay-wrapper{
    transform: translateY(-100%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $grey;
    z-index: 100;
    position: fixed;
    transition: 1s ease-in-out;
    overflow-y: hidden;
    &.active{
      transform: translateY(0);
    }
  }
  .mobile-nav-overlay{
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .mobile-nav{
      .mobile-nav_item{
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 15px;
          color: $white;
          transition: .3s;
          z-index: 102;
          &:hover{
              text-shadow: 1px 1px 1px $black;
          }
         
      }
  }
/* SECTION HERO */
.hero{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
        
    .hero-img{
        position: relative;
        width: 80%;
        img{
            width: 100%;
        }
        @media (max-width: 1440px) {
            width: 100%;
        }
        .hero-heading{
            position: absolute;
            top: 20%;
            left: 25%;
            color: $white;
            font-size: 82px;
            font-weight: 400;
            text-transform: uppercase;
            width: 100px;
            line-height: 1.4;
            @media (max-width: 990px) {
                font-size: 48px;
                line-height: 1.2;
            }
            @media (max-width: 545px) {
                font-size: 28px;
            }
        }
    }
    .scroll{
        padding-top: 25px;
        cursor: pointer;
    }
}

.section-title{
    width: 150px;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    color: $black;
    font-weight: 400;
    z-index: 10;
    position: absolute;
    top: 20px;
    left: 220px;
    @media (max-width: 1199px) {
        left: auto;
    }
}

/* SECTION SELECT */
.select{
    padding: 20px 0;
    .section-title{
        width: 85px;
    }
    .select-visa{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 125px;
        @media (max-width: 767px) {
            flex-direction: column;
        }
    }
}

.visa-card{
    width: 50%;
    margin-bottom: 60px;
    padding-left: 150px;
    .visa-title{
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: $black;
        text-transform: uppercase;
    }
    .visa-disc{
        width: 270px;
        height: 28px;
        font-size: 14px;
        line-height: 100%;
        margin: 20px 0;
        @media (max-width: 767px) {
            width: 350px;
        }
        @media (max-width: 545px) {
            width: 310px;
        }
    }
    .visa-btn{
        padding: 6px 25px;
        text-transform: uppercase;
        border: 1px solid $black;
        font-size: 8px;
        font-weight: 500;
        transition: .3s all;
        &:hover{
            box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.5);
        }
        &:active{
            color: $white;
            background-color: $black;
        }
    }
    @media (max-width: 990px) {
        padding-left: 70px;
    }
    @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        width: 100%;
    }
}

/* SECTION COSTS */
.costs{
    .section-title{
        width: 85px;
        color: $white;
    }
}

.cost-content{
    display: flex;
    justify-content: center;
    align-items: center;
    .cost-img{
        margin-bottom: 20px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
}
.cost-wrapper{
    display: flex;
    justify-content: center;
}

.cost-text{
    .cost-title{
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        /* or 31px */
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    .cost-disc{
        width: 400px;
        font-size: 14px;
        line-height: 120%;
        margin-bottom: 20px;
        @media (max-width: 545px) {
            width: 310px;
        }
    }
    .cost-btn{
          padding: 6px 25px;
        text-transform: uppercase;
        border: 1px solid $black;
        font-size: 8px;
        transition: .3s all;
        &:hover{
            box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.5);
        }
        &:active{
            color: $white;
            background-color: $black;
        }
    }
}

/* SECTION COUNTER */
.counters{
    .section-title{
        width: 200px;
    }
    margin-top: 130px;
    padding-top: 150px;
}

.counter-wrapper{
    display: flex;
    justify-content: space-around;
    .counter-item{
        width: 155px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .counter-item_num{
            font-size: 64px;
            line-height: 130%;
            /* or 83px */
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $color-primary;
            @media (max-width: 991px) {
                font-size: 46px;
            }
        }
        .counter-item_subtitle{
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            /* identical to box height, or 22px */
            color: $color-primary;
        }
        @media (max-width: 767px){
            width: 50%;
            margin-bottom: 30px;
        }
        @media (max-width: 545px) {
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
}

/* SECTION EDUCATION */
.education{
    padding-top: 200px;
    .education-img{
        display: flex;
        justify-content: center;
        position: relative;
        .education-text{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .education-heading{
                font-weight: 500;
                font-size: 70px;
                line-height: 120%;
                /* identical to box height, or 84px */
                text-align: center;
                color: $black;
                span{
                    color: $white;
                }
                @media(max-width: 1439px){
                    font-size: 38px;
                    color: $white;
                }
                @media (max-width: 545px) {
                    font-size: 32px;
                }
            }
            .education-disc{
                margin-top: 35px;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                /* or 19px */
                text-align: center;
                color: $white;
                @media (max-width: 545px) {
                    font-size: 11px;
                    margin-top: 25px;
                }
            }
        }
    }
    @media (max-width: 990px) {
        padding-top: 150px;
    }
}

/* SECTION PARTNERS */
.partners{
    margin-top: 100px;
    padding-top: 120px;
}

.partners-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .partners-disc{
        width: 666px;
        text-align: center;
        font-size: 14px;
        line-height: 120%;
        /* or 17px */
        @media (max-width: 991px) {
            width: auto;
        }
    }
    .partners-wrapper{
        margin-top: 45px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        
        .partner-item{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            height: 140px;
            background-color: $grey-light;

            @media (max-width:990px) {
                width: calc(100%/3 - 15px);
                margin: 10px 0;
            }
            @media (max-width: 545px) {
                width: calc(50% - 15px);
            }
        }
        @media (max-width:990px) {
            flex-wrap: wrap;
        }
    }
}

.cities{
    margin-top: 250px;
    .cities-wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 1999px) {
            flex-wrap: wrap;
        }
    }
    @media (max-width: 990px) {
        margin-top: 150px;
    }
}

.city-card{
    width: 250px;
    .city-card_text{
        width: 250px;
        font-size: 14px;
        line-height: 120%;
        /* or 17px */
        margin: 20px 0;
    }
    .city-btn{
        padding: 6px 15px;
        text-transform: uppercase;
        border: 1px solid $black;
        font-size: 8px;
        transition: .3s all;
        &:hover{
            box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.5);
        }
        &:active{
            color: $white;
            background-color: $black;
        }
    }
    @media (max-width: 1199px) {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        
    }
    @media (max-width:667px) {
        width: 100%;
    }
}

/* SECTION REVIEWS */

.reviews{
    margin-top: 250px;
    padding: 100px 0;
    .reviews-disc{
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
        p{
            text-align: center;
            width: 666px;
            font-size: 14px;
            line-height: 120%;
            /* or 17px */
        }
    }
    @media (max-width: 990px){
        margin-top: 100px;
    }
}

.review-slider{
    position: relative;
    /* padding-left: 85px; */
}
.slick-slide>div{
    justify-content: center;
    align-items: center;
    display: flex;
}

.slide{
    max-width: 350px;
    height: 146px;
    background-color: $grey-light;
    padding: 15px 20px;
    .slide-user{
        display: flex;
        align-items: center;
        .user-text{
            margin-left: 20px;
            .user-name{
                font-size: 18px;
                line-height: 130%;
                /* or 23px */
                text-transform: uppercase;

                color: #A7A7A7;
            }
            .user-comment{
                font-size: 14px;
                line-height: 120%;
                /* identical to box height, or 17px */
            }
        }
    }
    .slide-action{
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .action-time{
            display: flex;
            align-items: center;
            .time-text{
                font-size: 12px;
                line-height: 120%;
                /* or 14px */
                letter-spacing: 0.1em;
                color: #A7A7A7;
                margin-left: 10px;
            }
        }
        .action-btn{
                display: flex;
                font-size: 12px;
                line-height: 120%;
                /* or 14px */
                letter-spacing: 0.1em;
                color: #A7A7A7;
                text-transform: uppercase;
            .action-like{
                display: flex;
                align-items: center;
                margin-right: 25px;
                .like-num{
                    font-weight: 500;
                    font-size: 16px;
                    
                    /* identical to box height, or 24px */
                    letter-spacing: 0.1em;
                    color: $black;
                    margin-right: 5px;
                }
            }
            .action-reply{
                
            }
        }
    }
}

.slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    z-index: 10;
}
.slick-next{
   height: 20px;
   width: 20px;
   border-top: 5px solid $color-primary;
   border-right: 5px solid $color-primary;
   right: 0;
   transform: rotate(45deg);
    
}
.slick-prev{
    height: 20px;
    width: 20px;
    border-top: 5px solid $color-primary;
    border-right: 5px solid $color-primary;
    left: 0;
    transform: rotate(-135deg);
}

/* SECTION CONTACT */
.contact{
    padding-top: 120px;
    .section-title{
        width: 100px;
    }
    .contact-wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 990px) {
            flex-direction: column;
        }
    }

}
.contact-form{

    .contact-form_name{
    
    }
    button{
        font-weight: 500;
        font-size: 10px;
        line-height: 100%;
        cursor: pointer;
        /* identical to box height, or 8px */
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $black;
        padding: 6px 39px;
        border: 1px solid $black;
        transition: .3s all;
        &:hover{
            box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.5);
        }
        &:active{
            color: $white;
            background-color: $black;
        }
    }
    @media (max-width: 990px) {
        width: 100%;
        
    }
}
.form-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
    margin-bottom: 7px;
    color: #C4C4C4;
}
input{
    width: 270px;
    height: 35px;
    background-color: $grey-light;
    padding: 10px;
    margin-bottom: 25px;
    @media (max-width: 990px) {
        width: 100%;
    }
}
textarea{
    width: 270px;
    height: 100px;
    background-color: $grey-light;
    padding: 10px;
    margin-bottom: 25px;
    @media (max-width: 990px) {
        width: 100%;
    }
}

/* FOOTER */
.footer{
    padding: 20px 0;
    background-color: $grey-light;
}
.footer-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .social{
        display: flex;
        a{
            margin-right: 20px;
            &:last-child{
                margin-right: 0;
            }
        }
    }   
}
.rights{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */
    font-variant: small-caps;
    text-transform: uppercase;
    color: #C4C4C4;
}